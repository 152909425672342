import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const slides = document.querySelectorAll('.carousel-header__slide');

  if (1 < slides.length) {
    const carousel = document.querySelector('.carousel-header');

    const headerSwiper = new Swiper(carousel, {
      slidesPerView: 1,
      loop: true,
      autoplay: {
        delay: 5000,
      },
      navigation: {
        prevEl: carousel.querySelector('.swiper-button-prev'),
        nextEl: carousel.querySelector('.swiper-button-next'),
      },
      pagination: {
        el: carousel.querySelector('.swiper-pagination'),
        type: 'fraction',
        formatFractionCurrent: (number) => {
          if (10 > number) {
            return '0' + number;
          }
          return number;
        },
        formatFractionTotal: (number) => {
          if (10 > number) {
            return '0' + number;
          }
          return number;
        },
      },
    });

    headerSwiper.vtx = {
      playSlider: (button) => {
        headerSwiper.autoplay.start();
        headerSwiper.vtx.setAutoplayState('play');

        button.classList.add('play');
        button.setAttribute('aria-label', button.dataset.labelPause ?? '');
      },

      pauseSlider: (button) => {
        headerSwiper.autoplay.stop();
        headerSwiper.vtx.setAutoplayState('pause');

        button.classList.remove('play');
        button.setAttribute('aria-label', button.dataset.labelPlay ?? '');
      },

      getAutoplayState: () => {
        const state = localStorage.getItem('pause-sliders-autoplay');
        if (state) {
          // SUCCESS. There is a save state. Use that.
          return state;
        }

        const reducedMotion = window.matchMedia(
          '(prefers-reduced-motion: reduce)'
        );
        if (reducedMotion && reducedMotion.matches) {
          // SUCCESS. The user prefers les mouvement. Pause at load.
          return 'pause';
        }

        // Nothing was defined. Let's play!
        return 'play';
      },

      setAutoplayState: (state) => {
        localStorage.setItem('pause-sliders-autoplay', state);
      },
    };

    const toggleButton = carousel.querySelector('.swiper-toggle-play');
    toggleButton.addEventListener('click', function (event) {
      /* eslint-disable */
      switch (headerSwiper.vtx.getAutoplayState()) {
        case 'play':
          headerSwiper.vtx.pauseSlider(event.currentTarget);
          break;

        case 'pause':
          headerSwiper.vtx.playSlider(event.currentTarget);
          break;
      }
      /* eslint-enable */
    });

    // Initialize the slider on load state
    if ('pause' === headerSwiper.vtx.getAutoplayState()) {
      headerSwiper.vtx.pauseSlider(toggleButton);
    }
  }
});
